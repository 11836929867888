<template>
    <div v-if="isShow" class="v_all g-flex-align-center g-flex-justify-center">
      <div class="v-no-find">
        <div class="v-no-find-img"></div>
        <p @click="returnMainClick">返回首页</p>
      </div>
    </div>
</template>

<script>
  import NProgress from 'nprogress'
  import { apiInit } from '@/utils/api.js'
  import componentsRouter from "@/router/componentsRouter";
  // import { MenuList, ApiList } from '../../utils/testmenuArr.js'
  export default {
    data() {
      return {
        isShow: false
      }
    },
    created() {
      if(this.$route.query.token) {
        this.$global.setToken(this.$route.query.token) 
        this.$ws.setToken(this.$route.query.token)
      }
      if(this.$global.token) { //登录了 
        if(this.$global.MenuList.length === 0) { //是刷新页面
          NProgress.start()
          this.apiInitHandel()
        }else { //不是刷新页面 就是404了
           this.isShow=true
        } 
      }else { //没登陆
        this.$router.push('/login')
      }
    },
    methods: {
      returnMainClick() {
        this.$router.replace({ path: '/' })
      },
 

      // 初始化接口
      async apiInitHandel() {
        const { success, data } = await apiInit().catch(() => NProgress.done())
        NProgress.done()
        if(!success) return 
        console.log(data)
        this.$global.adminUser=data.user
        this.$global.ApiList = data.ApiList
        this.$global.config.bank = data.bank
        this.$global.config.system = data.system
        this.$global.config.kefu = data.kefu
        document.title = this.$global.config.system.title
        console.log(document.title)
        let res = this.tree(data.MenuList, 0)
        this.$global.MenuList = res.MenuList
         //设置默认路由(先反转push再反转  unshift效率太慢)
        res.asyncRoutes.reverse().push(
          {
            path: '/',
            name: 'layout',
            redirect: res.asyncRoutes[res.asyncRoutes.length-1].path + this.meunPath(res.asyncRoutes[res.asyncRoutes.length -1]),
            component:componentsRouter.Layout,
          }
        )

        res.asyncRoutes.reverse()
        
        console.log('结果', res)

        this.$router.addRoutes(res.asyncRoutes)

        this.$router.push({path:this.$route.path}).catch(() => {
            //404页面不存在
            NProgress.done()
            this.isShow=true
        })
      },

      //寻找默认第一组菜单路径
      meunPath(route) {
          if (route.children) {
            return '/' + route.children[0].path+this.meunPath(route.children[0])
          }
          return '';
      },
      // 递归处理两个数据  异步路由和左边菜单
      tree(MenuArr, parentId) {
        //菜单
        let MenuList = []
        //异步路由
        let asyncRoutes=[]

        MenuArr.forEach(item => {
          if(item.parent_id === parentId) {
            let menu = {
              id: item.id,
              title: item.title,
              icon: item.icon,
              path: item.path
            }

            let router = {
              path:item.path,
              component:componentsRouter[item.component],
              meta: {
                  id:item.id,
                  icon: item.icon,
                  title: item.title,
                  // keepAlive: item.parent_id == 14 || item.id == 14
              }
            }
            asyncRoutes.push(router)
            let children = this.tree(MenuArr, item.id)
            if(children.asyncRoutes.length > 0) router.children = children.asyncRoutes
            // 只push需要显示的菜单
            if(item.is_show==1) {
              //只push需要显示的菜单
              MenuList.push(menu)
              if (children.MenuList.length > 0) menu.children = children.MenuList
            }
          }
        })
        return { asyncRoutes, MenuList }
      },


      // // 处理异步路由数据
      // treeData(MenuArr, parentId) {
      //   return MenuArr.filter(({ parent_id }) => {
      //     return parent_id === parentId
      //   }).map(item => {
      //       let router = {
      //         path:item.path,
      //         component:componentsRouter[item.component],
      //         meta:{
      //             id:item.id,
      //             icon: item.icon,
      //             title: item.title,
      //         }
      //       }
      //     const children = this.treeData(MenuArr, item.id)
      //     if(children.length === 0) {
      //       return router
      //     }
      //     return Object.assign({ ...router, children })
      //   })
      // }


    }
  }
</script>

<style lang="scss">
.v_all {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  .v-no-find {
    .v-no-find-img {
      width: 600px;
      height: 500px;
      background-image: url('/img/404.png');
      background-size: cover;
      background-repeat: no-repeat;
    }
    p {
      text-align: center;
      font-size: 20px;
      color: #93cdfc; 
      line-height: 40px;
      cursor: pointer;
      text-decoration: underline;
    }
  }
}
</style>