//异步路由组件
export default {
  //布局组件
  Layout: () => import('@/views/layout/Layout.vue'),
  //空组件
  Empty: () => import('@/views/layout/LayoutSecond.vue'),
  //刷新组件
  Refresh: () => import( '@/views/layout/LayoutSecond.vue'),
  // 首页
  MainView: () => import('@/views/main/MainView.vue'),
  //菜单管理
  MenuList: () => import('@/views/system/menulist/MenuList.vue'),

  //角色列表
  RoleList: () => import('@/views/system/rolelist/RoleList.vue'),

  //管理员列表
  ManageList: () => import('@/views/system/managelist/ManageList.vue'),

  //日志列表
  LogList:() => import('@/views/system/loglist/LogList.vue'),

  // 系统配置
  SystemConfig: () => import('@/views/system/systemconfig/SystemConfig.vue'),
  

  //商家 发布垫付任务
  TaskIssueDianFu: () => import('@/views/taskissue/taskissuedianfu/TaskIssueDianFu.vue'),

  // 商家 充值
  MoneyStoreRecharge: () => import('@/views/moneystore/moneystorerecharge/MoneyStoreRecharge.vue'),

  // 商家 充值列表
  MoneyStoreRechargeList: () => import('@/views/moneystore/moneystorechargelist/MoneyStoreRechargeList.vue'),

  //商家 店铺列表
  StoreList: () => import('@/views/store/storelist/StoreList.vue'),

  // 用户列表
  UserList: () => import('@/views/user/userlist/UserList.vue'),

  // 用户资金记录流水列表
  UserMoneyList: () => import('@/views/user/usermoneylist/UserMoneyList.vue'),

  // 用户提现列表
  UserCashOutList: () => import('@/views/user/usercashoutlist/UserCashOutList.vue'),

  // 实名审核列表
  UserRealList: () => import('@/views/user/userreallist/UserRealList.vue'),


  // 用户黑名单
  UserBlackList: () => import('@/views/user/userblacklist/UserBlackList.vue'),

  // 商家资金记录
  MoneyStoreDetail: () => import('@/views/moneystore/moneystoredetail/MoneyStoreDetail.vue'),

  // 任务管理 后台垫付任务列表
  TaskAdminList: () => import('@/views/task/taskadminlist/TaskAdminList.vue'),

  // 任务管理 后台垫付任务订单列表
  TaskAdminListOrder: () => import('@/views/task/taskadminlistorder/TaskAdminListOrder.vue'),

  // 任务管理 商家任务列表
  TaskStoreList: () => import('@/views/task/taskstorelist/TaskStoreList.vue'),

  // 任务订单列表
  TaskListOrder: () =>  import('@/views/task/taskstorelistorder/TaskListOrder.vue'),

  // 商家获取前台账户列表
  AccountList: () => import('@/views/account/accountlist/AccountList.vue'),


  //骗子库
  UserPianziList: () => import('@/views/user/userpianzilist/UserPianziList.vue'),

  // 店铺黑名单列表
  StoreBlackList:() => import('@/views/store/storeblacklist/StoreBlackList.vue'),

  // 商家账号黑名单列表
  AccountStoreBlackList: () => import('@/views/account/accountstoreblacklist/AccountStoreBlackList.vue'),

  // 任务金额配置
  TaskMoneyConfig: () => import('@/views/task/taskmoneyconfig/TaskMoneyConfig.vue'),

  // 商家列表
  BussinessList: () => import('@/views/bussinessmanage/bussinesslist/BussinessList.vue'),

  // 平台佣金配置
  SystemPlatConfig: ()  => import('@/views/system/systemplatconfig/SystemPlatConfig.vue'),

  // 商家公告列表
  BussBulletinList: ()  => import('@/views/system/bussbulletinlist/BussBulletinList.vue'),

  // 银行卡列表
  BankList: () => import('@/views/system/banklist/BankList.vue'),

  // 修改商家密码
  BussinessChangePwd: () => import('@/views/bussinessmanage/bussinesschangepwd/BussinessChangePwd.vue'),

  // 用户提现账号列表
  UserCashAccountList:  () => import('@/views/user/usercashaccountlist/UserCashAccountList.vue'),

  // 任务统计
  TotalManageTask:  () => import('@/views/totalmanage/totalmanagetask/TotalManageTask.vue'),

  // 代理列表
  DaiLiList: () => import('@/views/dailimanage/daililist/DaiLiList.vue'),

  // 代理提现列表
  DaiLiCashOutList: () => import('@/views/dailimanage/dailicashoutlist/DaiLiCashOutList.vue'),


  // 发布预售任务
  TaskIssueYuSou: () => import('@/views/taskissue/taskissueyusou/TaskIssueYuSou.vue'),

  // 发布浏览任务
  TaskIssueLiuLan: () => import('@/views/taskissue/taskissueliulan/TaskIssueLiuLan.vue'),


  // 商家预售任务列表
  TaskStoreYuSouList: () => import('@/views/task/taskstoreyusoulist/TaskStoreYuSouList.vue'),

  // 商家预售任务订单列表
  TaskStoreListYuSouOrder: () => import('@/views/task/taskstoreyusouolistorder/TaskStoreListYuSouOrder.vue'),

  // 商家浏览任务列表
  TaskStoreLiuLanList: () => import('@/views/task/taskstoreliulanlist/TaskStoreLiuLanList.vue'),

  // 商家浏览任务订单列表
  TaskStoreListLiuLanOrder: () => import('@/views/task/taskstoreliulanlistorder/TaskStoreListLiuLanOrder.vue'),


  // 后台预售任务列表
  TaskAdminYuSouList: () => import('@/views/task/taskadminyusoulist/TaskAdminYuSouList.vue'),

  // 后台预售任务订单列表
  TaskAdminYuSouListOrder: () => import('@/views/task/taskadminyusoulistorder/TaskAdminYuSouListOrder.vue'),

  // 后台浏览任务列表
  TaskAdminLiuLanList: () => import('@/views/task/taskadminliulanlist/TaskAdminLiuLanList.vue'),

  // 后台浏览任务订单列表
  TaskAdminLiuLanListOrder: () => import('@/views/task/taskadminliulanlistorder/TaskAdminLiuLanListOrder.vue'),

  // 买家黑名单
  AccountBuyBlackList: () =>  import('@/views/account/accountbuyblacklist/AccountBuyBlackList.vue'),
}